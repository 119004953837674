import React from 'react'
import Layout from "../templates/Layout"
import { Hero, Container, Row, } from "@edinburghairport/runway"
import { Link } from "gatsby"


class About extends React.Component {

  render() {

    const content = {
      background: {
        file: {
          url: "https://edinburghairport.s3-eu-west-1.amazonaws.com/files/acp2019/about_bg.png"
        }
      },
      heading: "About",
    };

    return (
      <Layout>
        <Hero content={content} />
        <div className="section section__about">
          <Container>
            <Row>
              <div className="col-12">
                <p className="breadcrumb"><Link href="https://airspacechange.caa.co.uk/">Home</Link> > About</p>
              </div>
            </Row>
            <Row>

              <div className="col-md-12">
                <div className="section__about">
                  <p className="section__about--intro">On 1 July 2019 we launched an initiative to change the way airspace is used at Edinburgh Airport. Our ultimate aim is to make the airport more efficient, to accommodate rising demand from travellers in Scotland and beyond.</p>
                  <p>It’s a detailed process that will take a number of years. We’ll be working closely with interested groups and individuals, and running a public consultation.</p>
                  <p>Our programme will follow steps laid down by the CAA, in a process known as <a href="https://publicapps.caa.co.uk/modalapplication.aspx?appid=11&mode=detail&id=8127">CAP1616.</a> The CAA will assess the programme at various stages. </p>
                  <p>Find out more and see the latest updates about this work <a href="https://airspacechange.caa.co.uk/PublicProposalArea?pID=163">on the CAA’s portal.</a></p> 
                  <p>You can contact us for more information at <a href="mailTo:airspace_change@edinburghairport.com">airspace_change@edinburghairport.com</a></p>
                </div>
              </div>
            </Row>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default About